import * as yup from 'yup';

const joinWaitListSchema = yup.object().shape({
  firstName: yup.string().required(`First name is required`),
  lastName: yup.string(),
  email: yup.string().required(`Email is required`),
  country: yup.string().required(`Country location is required`),
  quantity: yup
    .number()
    .min(1, `Quantity of kits should be at least 1`)
    .required(`Quantity of kits is required`),
});

export default joinWaitListSchema;
