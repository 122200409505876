/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/function-component-definition */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { sortOptions } from '@/helper/sortOptions';
import { ArrowDown, ArrowUp } from '../../icons/Arrows';

interface Option {
  value: string;
  label: string;
  code?: string;
  category?: string;
}

interface IconProps {
  isOpen: boolean;
}

const Icon: React.FC<IconProps> = ({ isOpen }) => (
  <div className="absolute bottom-[calc(50%-8px)] right-[16px]">
    {isOpen ? <ArrowUp /> : <ArrowDown />}
  </div>
);

interface CustomSelectProps {
  placeHolder: string;
  options: Option[];
  isSearchable?: boolean;
  value: string;
  name?: string;
  onSelect: (value: Option) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  error?: string | null;
  categorizeOptions?: boolean;
  isSorted?: boolean;
  priorityCategory?: string;
  dropHeight?: string;
  hideValue?: boolean;
  CategoryOptions?: Option[];
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  placeHolder,
  options,
  isSearchable,
  onSelect,
  onChange,
  value,
  name,
  onBlur,
  error,
  categorizeOptions = false,
  isSorted = false,
  priorityCategory,
  dropHeight,
  hideValue = false,
  CategoryOptions = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedValue, setSelectedValue] = useState<Option | null>(null);
  const [other, setOther] = useState(false);
  const [searchValue, setSearchValue] = useState(``);
  const focusRef = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLDivElement>(null);
  const otherInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSearchValue(``);
  }, [isOpen]);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (focusRef.current && !focusRef.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    window.addEventListener(`click`, handler);
    return () => {
      window.removeEventListener(`click`, handler);
    };
  }, []);

  useEffect(() => {
    if (error && focusRef.current) {
      focusRef.current.scrollIntoView({ behavior: `smooth`, block: `center` });
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => {
      if (otherInputRef.current) {
        otherInputRef.current.focus();
        otherInputRef.current.setSelectionRange(0, 0);
      }
    }, 100);
  }, [other]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (options.length === 1 && options[0].value === `N/A`) {
      // setSelectedValue(options[0]);
      onSelect(options[0]);
    }
  };

  const getDisplay = () => {
    if (value || options.some((op) => op.value === ``)) {
      return options.find((op) => op.value === value)?.label
        ? options.find((op) => op.value === value)?.label
        : hideValue
        ? placeHolder
        : value;
    }
    return placeHolder;
  };

  const onItemClick = (option: Option) => {
    if (option.value === `other`) {
      setOther(true);
      onSelect({} as Option);
    } else {
      // setSelectedValue(option);
      onSelect(option);
      setSearchValue(``);
    }
    setIsOpen(false);
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };

  const getFilteredOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter((option) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase()),
    );
  };

  const renderOptions = () => {
    if (
      categorizeOptions &&
      getFilteredOptions().some((option) => option.category)
    ) {
      const categories: { [key: string]: Option[] } = {};
      const otherCategory: Option[] = [];
      let sortedCategories: { [key: string]: Option[] } = {};

      getFilteredOptions().forEach((option) => {
        if (!option.category || option.category === `Other`) {
          otherCategory.push(option);
        } else {
          if (!categories[option.category]) {
            categories[option.category] = [];
          }
          categories[option.category].push(option);
        }
      });

      if (priorityCategory && categories.hasOwnProperty(priorityCategory)) {
        sortedCategories[priorityCategory] = categories[priorityCategory];
        delete categories[priorityCategory];
      }

      sortedCategories = {
        ...sortedCategories,
        ...Object.entries(categories)
          .sort(([a], [b]) => a.localeCompare(b))
          .reduce<{ [key: string]: Option[] }>((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {}),
      };

      sortedCategories.Other = otherCategory;

      return Object.entries(sortedCategories).map(
        ([category, categoryOptions]) => (
          <div key={category}>
            {category && (
              <div className="bg-[#e6e6e6] px-[16px] py-[10px] border-b border-[#F4F8F5] text-navy-blue text-[12px] font-black uppercase">
                {CategoryOptions.find((eq) => eq.value === category)
                  ? CategoryOptions.find((eq) => eq.value === category)?.label
                  : category}
              </div>
            )}
            {sortOptions(categoryOptions).map((option) => (
              <div
                onClick={() => onItemClick(option)}
                key={option.value}
                className="p-[16px] cursor-pointer text-base text-navy-blue font-medium hover:bg-gray-100 border border-b-gray"
              >
                {option.label}
              </div>
            ))}
          </div>
        ),
      );
    }
    const options = isSorted
      ? sortOptions(getFilteredOptions())
      : getFilteredOptions();
    return options.map((option, index) => (
      <div
        onClick={() => onItemClick(option)}
        key={option.value + index}
        className="p-[16px] cursor-pointer text-base text-navy-blue font-medium hover:bg-gray-100 border border-b-gray"
      >
        {option.label}
      </div>
    ));
  };

  return (
    <div
      className="relative mt-3"
      ref={focusRef}
      onBlur={onBlur}
      onClick={handleToggle}
    >
      {isSearchable ? (
        <>
          {isOpen ? (
            <div className="relative">
              <input
                className={`w-full bg-inherit border rounded-lg p-[16px] text-base text-navy-blue font-medium outline-navy-blue  ${
                  error ? ` border-[#dc2626]` : ` border-[#a3a1a8]`
                }`}
                placeholder="Search..."
                onChange={onSearch}
                value={searchValue}
              />
              <Icon isOpen={isOpen} />
            </div>
          ) : (
            <div className="relative">
              <input
                className={`w-full bg-inherit border rounded-lg p-[16px] text-base text-navy-blue font-medium outline-navy-bluetext-navy-blue ${
                  ((value && !hideValue) ||
                    options.find((eq) => eq.value === value)?.label) &&
                  `select-input-class`
                } ${error ? ` border-[#dc2626]` : ` border-[#a3a1a8]`}`}
                readOnly
                placeholder={getDisplay()}
                // onClick={handleToggle}
                value={
                  options.find((eq) => eq.value === value)?.label
                    ? options.find((eq) => eq.value === value)?.label
                    : value && !hideValue
                    ? value
                    : ``
                }
              />
              <Icon isOpen={isOpen} />
            </div>
          )}
        </>
      ) : (
        <>
          {other ? (
            <div className="relative">
              <input
                ref={otherInputRef}
                className={`w-full bg-inherit border rounded-lg p-[16px] text-base text-navy-blue font-medium outline-navy-bluetext-navy-blue  ${
                  error ? ` border-[#dc2626]` : ` border-[#a3a1a8]`
                }`}
                placeholder="Please type here..."
                value={value}
                name={name}
                onChange={onChangeInput}
              />
              <div
                onClick={() => {
                  // setSelectedValue(null);
                  setOther(false);
                }}
                className="absolute bottom-[calc(50%-8px)] right-[16px] cursor-pointer"
              >
                <img src="/icons/multiply.svg" alt="multiply" />
              </div>
            </div>
          ) : (
            <div className="relative" onClick={handleToggle}>
              <input
                className={`w-full bg-inherit border rounded-lg p-[16px] text-base text-navy-blue font-medium cursor-pointer outline-navy-bluetext-navy-blue ${
                  ((value && !hideValue) ||
                    options.find((eq) => eq.value === value)?.label) &&
                  `select-input-class`
                } ${error ? ` border-[#dc2626]` : ` border-[#a3a1a8]`}`}
                placeholder={getDisplay()}
                value=""
                readOnly
              />
              <Icon isOpen={isOpen} />
            </div>
          )}
        </>
      )}

      {isOpen && (
        <div
          ref={optionRef}
          className={`absolute z-10 mt-1 w-full ${
            dropHeight || `max-h-44`
          } overflow-y-auto scroll-style border border-[#c6c5c9] rounded-md bg-white shadow-md z-10`}
        >
          {getFilteredOptions().length ? (
            renderOptions()
          ) : (
            <div
              onClick={() => setIsOpen(false)}
              className="p-[16px] cursor-pointer text-base text-navy-blue font-medium hover:bg-gray-100 border border-b-[#a3a1a8]"
            >
              No result found
            </div>
          )}
        </div>
      )}
      {other && (
        <div className="text-[#999900] text-[14px] mt-[12px] flex flex-wrap gap-[2px]">
          <span>To return to viewing options please click the</span>
          <div className="flex">
            "<img src="/icons/multiply.svg" alt="multiply" />"
          </div>
          <span>icon</span>
        </div>
      )}
      {error && (
        <div className="text-[#dc2626] text-base mt-[12px]">{error}</div>
      )}
    </div>
  );
};

export default CustomSelect;
