export const getHints = (password: string) => {
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>áéíóúüñ¿¡âêîôûàèìòùç]/.test(
    password,
  );
  const hasDigit = /[0-9]/.test(password);
  const hints = [
    { text: `At least 12 characters`, satisfied: password.length >= 12 },
    { text: `At least one number`, satisfied: hasDigit },
    { text: `At least one uppercase letter`, satisfied: hasUppercase },
    { text: `At least one lowercase letter`, satisfied: hasLowercase },
    {
      text: `At least one special symbol (e.g *$#@)`,
      satisfied: hasSpecialChar,
    },
  ];
  return hints || [];
};
