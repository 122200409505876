const countryOptions = [
  {
    label: `United States`,
    value: `US`,
    hasSubOptions: false,
  },
  {
    label: `Canada`,
    value: `CA`,
    hasSubOptions: false,
  },
];

export default countryOptions;
