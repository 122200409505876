import React from 'react';
import RoutingLoader from '../../../assets/spinners/RoutingLoader';
import Modal from '../../../components/modal';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

function RoutingCheckout({ isOpen, handleClose }: Props) {
  return (
    <Modal
      active={isOpen}
      handleClose={handleClose}
      width="w-[243px] md:w-[306px] rounded-[20px]"
    >
      <div className="flex flex-col items-center p-6 gap-y-3">
        <RoutingLoader />
        <div className="flex flex-col gap-y-3 items-center">
          <h3 className="text-[#19233F] text-base md:text-[20px] font-bold">
            Routing to checkout screen
          </h3>
          <div className="flex gap-x-2">
            <div className="py-0.5">
              <img
                src="/images/alarm-clock.svg"
                alt="alarm-clock"
                className="w-4 h-4"
              />
            </div>
            <p className="text-[#484352] text-sm">
              This will take about 5 seconds!
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default RoutingCheckout;
