function EyeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M3.22098 10.4871C3.43522 10.8416 3.32151 11.3027 2.96701 11.5169C2.6125 11.7311 2.15144 11.6174 1.9372 11.2629C1.63263 10.7589 1.42599 10.3246 1.30448 10.0443C1.13048 9.64283 1.15573 9.19087 1.3677 8.81235C1.69114 8.23476 2.50824 6.8995 3.76751 5.69976C5.02389 4.50277 6.79173 3.375 8.9998 3.375C11.2079 3.375 12.9757 4.50277 14.2321 5.69976C15.4914 6.8995 16.3085 8.23476 16.6319 8.81235C16.8439 9.19087 16.8691 9.64283 16.6951 10.0443C16.5736 10.3246 16.367 10.7589 16.0624 11.2629C15.8482 11.6174 15.3871 11.7311 15.0326 11.5169C14.6781 11.3027 14.5644 10.8416 14.7786 10.4871C15.0236 10.0817 15.1936 9.73117 15.2967 9.49832C14.9924 8.962 14.2717 7.80924 13.1974 6.78578C12.0878 5.72862 10.6723 4.875 8.9998 4.875C7.32734 4.875 5.9118 5.72862 4.80219 6.78578C3.72795 7.80924 3.00723 8.962 2.70292 9.49832C2.80602 9.73117 2.97601 10.0817 3.22098 10.4871Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.9998 6C6.92874 6 5.2498 7.67893 5.2498 9.75C5.2498 11.8211 6.92874 13.5 8.9998 13.5C11.0709 13.5 12.7498 11.8211 12.7498 9.75C12.7498 7.67893 11.0709 6 8.9998 6ZM6.7498 9.75C6.7498 8.50736 7.75716 7.5 8.9998 7.5C10.2424 7.5 11.2498 8.50736 11.2498 9.75C11.2498 10.9926 10.2424 12 8.9998 12C7.75716 12 6.7498 10.9926 6.7498 9.75Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default EyeIcon;
