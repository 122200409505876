import React from 'react';

export default function Checkmark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_13805_161181)">
        <path
          d="M12.75 2.50351C11.6104 1.84405 10.3166 1.49784 9 1.50001C4.85775 1.50001 1.5 4.85776 1.5 9.00001C1.5 13.1423 4.85775 16.5 9 16.5C13.1423 16.5 16.5 13.1423 16.5 9.00001C16.499 8.48501 16.449 7.98501 16.35 7.50001"
          stroke="#13C700"
          strokeWidth="1.38462"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 9.375C6 9.375 7.125 9.375 8.625 12C8.625 12 12.7943 5.12475 16.5 3.75"
          stroke="#13C700"
          strokeWidth="1.38462"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13805_161181">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
