import React from 'react';

interface Props {
  width: string;
  children: React.ReactNode;
  active: boolean;
  handleClose: () => void;
}

function Modal({
  width = `w-full md:w-1/2`,
  children,
  active,
  handleClose,
}: Props) {
  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onClick={handleClose}
        onKeyDown={(e) => {
          if (e.key === `Escape`) handleClose();
        }}
        className={`${
          active ? `visible` : `hidden`
        } fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-40 transform scale-110 transition-all ease-in-out duration-300`}
        aria-label="Close modal"
      />
      <div
        className={`${
          active ? `visible` : `hidden`
        } mx-auto ${width} z-30 flex flex-col bg-white shadow-md fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 box-border`}
      >
        {children}
      </div>
    </>
  );
}

export default Modal;
