/* eslint-disable react/no-array-index-key */
/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/require-default-props */
import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import EyeSlashIcon from '../../icons/EyeSlashIcon';
import EyeIcon from '../../icons/EyeIcon';
import { getHints } from './hints';

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  isPassword?: boolean;
  error?: string | null;
  readOnly?: boolean;
  showHint?: boolean;
}

const CustomInput: React.FC<CustomInputProps> = ({
  isPassword,
  error,
  value,
  readOnly,
  showHint,
  type,
  onChange,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [hintDisplay, setHintDisplay] = useState(false);
  const inputType = isPassword && !showPassword ? 'password' : 'text';

  const focusRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (focusRef.current && !focusRef.current.contains(e.target as Node)) {
        setHintDisplay(false);
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);

  useEffect(() => {
    if (error && focusRef.current) {
      focusRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [error]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (type === 'number') {
      if (/^\d*$/.test(inputValue)) {
        onChange?.(e);
      }
    } else {
      onChange?.(e);
    }
  };

  return (
    <div className="relative">
      <div className="relative w-full">
        <input
          {...rest}
          onChange={handleInputChange}
          ref={focusRef}
          onFocus={() => setHintDisplay(true)}
          readOnly={readOnly}
          value={value}
          type={type === 'number' ? 'text' : inputType}
          className={`w-full mt-3 ${
            readOnly ? 'bg-[#a3a1a8] input-outline-none' : 'bg-inherit'
          }  border rounded-lg p-[16px] text-base text-navy-blue font-medium outline-navy-blue ${
            error ? ' border-[#dc2626]' : ' border-[#c6c5c9]'
          }`}
        />
        {isPassword && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 px-3 py-2"
          >
            {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
          </button>
        )}
      </div>
      {error && (
        <div className="text-[#dc2626] text-base mt-[12px]">{error}</div>
      )}
      {showHint &&
        hintDisplay &&
        value &&
        getHints(value as string) &&
        getHints(value as string).length &&
        getHints(value as string).some((eq) => !eq.satisfied) &&
        isPassword &&
        showHint && (
          <div
            className={`absolute flex flex-col gap-[9px] z-10 top-[62px] px-[12px] py-[12px] w-full border border-[#c6c5c9] rounded-md bg-white shadow-md`}
          >
            {getHints(value as string).map((hint, index) => (
              <div
                key={index}
                className="flex items-center gap-[8px] text-[14px] font-normal text-[#585858]"
              >
                {hint.satisfied ? (
                  <img src="/icons/hint-success.svg" alt="hint-success" />
                ) : (
                  <img src="/icons/hint-error.svg" alt="hint-success" />
                )}
                {hint.text}
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

export default CustomInput;
