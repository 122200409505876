function EyeSlashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M15.5301 2.46967C15.823 2.76256 15.823 3.23744 15.5301 3.53033L3.53015 15.5303C3.23725 15.8232 2.76238 15.8232 2.46949 15.5303C2.17659 15.2374 2.17659 14.7626 2.46949 14.4697L14.4695 2.46967C14.7624 2.17678 15.2373 2.17678 15.5301 2.46967Z"
        fill="currentColor"
      />
      <path
        d="M11.8495 4.02903C11.0047 3.63122 10.0523 3.375 8.9998 3.375C6.79173 3.375 5.02389 4.50277 3.76751 5.69976C2.50824 6.8995 1.69114 8.23476 1.3677 8.81235C1.15573 9.19087 1.13048 9.64283 1.30448 10.0443C1.42599 10.3246 1.63263 10.7589 1.9372 11.2629C2.15144 11.6174 2.6125 11.7311 2.96701 11.5169C3.32151 11.3027 3.43522 10.8416 3.22098 10.4871C2.97601 10.0817 2.80602 9.73117 2.70292 9.49832C3.00723 8.962 3.72795 7.80924 4.80219 6.78578C5.9118 5.72862 7.32734 4.875 8.9998 4.875C9.60135 4.875 10.1697 4.98543 10.7024 5.17604L11.8495 4.02903Z"
        fill="currentColor"
      />
      <path
        d="M13.2677 6.85342C14.3031 7.85959 14.9991 8.97378 15.2967 9.49832C15.1936 9.73117 15.0236 10.0817 14.7786 10.4871C14.5644 10.8416 14.6781 11.3027 15.0326 11.5169C15.3871 11.7311 15.8482 11.6174 16.0624 11.2629C16.367 10.7589 16.5736 10.3246 16.6951 10.0443C16.8691 9.64283 16.8439 9.19087 16.6319 8.81235C16.3168 8.24963 15.5331 6.96774 14.3285 5.79266L13.2677 6.85342Z"
        fill="currentColor"
      />
      <path
        d="M8.99982 6C9.27238 6 9.53815 6.02908 9.79419 6.0843L8.25082 7.62767C7.6107 7.85358 7.1034 8.36089 6.87749 9.00101L5.33412 10.5444C5.2789 10.2883 5.24982 10.0226 5.24982 9.75C5.24982 7.67893 6.92875 6 8.99982 6Z"
        fill="currentColor"
      />
      <path
        d="M8.99982 12C8.73666 12 8.48406 11.9548 8.24934 11.8718L7.12355 12.9976C7.67541 13.3171 8.31626 13.5 8.99982 13.5C11.0709 13.5 12.7498 11.8211 12.7498 9.75C12.7498 9.06644 12.5669 8.4256 12.2474 7.87374L11.1216 8.99952C11.2046 9.23424 11.2498 9.48684 11.2498 9.75C11.2498 10.9926 10.2425 12 8.99982 12Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default EyeSlashIcon;
