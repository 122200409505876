/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import CustomInput from '@/components/form/Input';
import CustomSelect from '@/components/form/SelectBox';
import Checkmark from '@/components/icons/Checkmark';
import useForm from '@/common/useForm';
import joinWaitListSchema from '@/schema/join-waitlist.schema';
import axios from 'axios';
import { JoinWaitListInterface } from '@/types/join-waitlist.interface';
import { axiosErrorToast, toastSuccess } from '@/utils/toast';
import ButtonSpinner from '@/assets/spinners/ButtonSpinner';
import countryOptions from './countryOption';
import RoutingCheckout from '../home/popup-modal/routing-checkout-modal';

function JoinWaitList() {
  const [isLoading, setIsLoading] = useState(false);
  const [isRoutingOpen, setIsRoutingOpen] = useState(false);

  const {
    values,
    handleChange,
    errors,
    handleSelect,
    handleSubmit,
    handleBlur,
    handleReset,
  } = useForm({
    initialValues: {
      firstName: ``,
      lastName: ``,
      email: ``,
      country: ``,
      quantity: 0,
    },
    validationSchema: joinWaitListSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        setIsRoutingOpen(false);
        const data: JoinWaitListInterface = {
          ...values,
          quantity: Number(values.quantity),
        };
        const apiBaseUrl = process.env.GATSBY_API_BASE_URL;
        const response = await axios.post(
          `${apiBaseUrl}/orders/waitlist`,
          data,
        );
        const responseData = response.data.data;
        setIsRoutingOpen(true);
        window.open(responseData.url, `_self`);
        toastSuccess(`Your response has been recorded`);
        setIsLoading(false);
        handleReset();
      } catch (error) {
        setIsLoading(false);
        axiosErrorToast(error);
      }
    },
  });

  function handleClose() {
    setIsRoutingOpen(false);
  }

  return (
    <section className="flex flex-col justify-center items-center mt-[138px] xl:mt-[146px] px-5">
      <RoutingCheckout isOpen={isRoutingOpen} handleClose={handleClose} />
      <div className="flex justify-center items-center py-5 xl:py-4">
        <h1 className="text-2xl text-navy-blue md:text-3xl font-bold">
          Join The <span className="text-primary font-bold">DeepGut</span> Paid
          Waitlist
        </h1>
      </div>
      <div className="w-[335px] md:w-[640px] xl:w-[1178px] flex flex-col xl:flex-row md:mt-[56px]">
        <div className="flex flex-col xl:flex-row justify-center items-center xl:items-start xl:gap-x-[120px]">
          <div className="w-[335px] md:w-[480px]">
            <img
              src="/images/test-kit-box.svg"
              alt=""
              className="bg-[#D3D3D3] bg-opacity-20 rounded"
            />
          </div>
          <div className="md:w-[578px] mt-10 xl:mt-0">
            <h6 className="text-[#A3A1A8] text-base font-medium pb-8">
              Vitract Gut Microbiome Tests
            </h6>
            <div>
              <h2 className="text-3xl font-bold text-navy-blue">
                DeepGut by Vitract
              </h2>
              <span className="italic text-[#A3A1A8] text-base font-medium py-4">
                Practitioner only
              </span>
              <p className="text-base font-medium text-[#484352]">
                Reserve your DeepGut test kit today by joining our exclusive
                2-week paid waitlist. Pay just{` `}
                <span className="text-primary font-bold">
                  $99 USD ($140 CAD)
                </span>
                {` `}
                upfront to lock in your discounted price of{` `}
                <span className="font-bold">$269 USD ($388 CAD)</span>, a
                special offer from our standard price of{` `}
                <span className="font-bold">$299 USD / $430 CAD</span>. After 2
                weeks, your test kit will be shipped, and you'll only need to
                pay the remaining balance before it’s sent out.
              </p>
              <div className="">
                <p className="text-[#484352] text-base font-medium py-6">
                  What’s Included in Your Kit:
                </p>
                <ul className="flex flex-col list-none gap-y-[16px] text-[#484352]">
                  <li className="flex gap-x-2 text-base font-medium">
                    <span className="py-[2px]">
                      <Checkmark />
                    </span>
                    Collection Tube with Swab
                  </li>
                  <li className="flex gap-x-2 text-base font-medium">
                    <span className="py-[2px]">
                      <Checkmark />
                    </span>
                    Padded Return Envelope & Shipping Label
                  </li>
                  <li className="flex gap-x-2 text-base font-medium">
                    <span className="py-[2px]">
                      <Checkmark />
                    </span>
                    Plastic Gloves
                  </li>
                  <li className="flex gap-x-2 text-base font-medium">
                    <span className="py-[2px]">
                      <Checkmark />
                    </span>
                    Plastic Ziplock Bag & Biohazard Bag
                  </li>
                  <li className="flex gap-x-2 text-base font-medium">
                    <span className="py-[2px]">
                      <Checkmark />
                    </span>
                    Extra Barcode Label (for your records)
                  </li>
                </ul>
                <p className="text-[#484352] text-base font-medium py-6">
                  Secure your spot on the paid waitlist by clicking the link
                  below
                </p>
                <span className="text-xl font-bold text-primary">
                  $99 USD / $140CAD
                </span>

                <form className="pt-8" onSubmit={handleSubmit}>
                  <div className="flex flex-col gap-y-5 md:gap-y-8 mr-5 mb-12">
                    <div className="flex flex-col md:flex-row items-start gap-5 md:gap-6 justify-between">
                      <div className="flex-1 w-full font-bold mb-3">
                        First name
                        <CustomInput
                          placeholder="Enter your first name"
                          type="text"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={() => handleBlur(`firstName`)}
                          error={errors.firstName}
                        />
                      </div>
                      <div className="flex-1 w-full font-bold mb-3">
                        Last name
                        <CustomInput
                          placeholder="Enter your last name"
                          type="text"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={() => handleBlur(`lastName`)}
                          error={errors.lastName}
                        />
                      </div>
                    </div>
                    <div className="flex-1 w-full font-bold mb-3">
                      Email address
                      <CustomInput
                        placeholder="Enter your email"
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={() => handleBlur(`email`)}
                        error={errors.email}
                      />
                    </div>
                    <div className="flex-1 w-full font-bold mb-3">
                      Country
                      <CustomSelect
                        options={countryOptions}
                        placeHolder="Enter your country"
                        onSelect={(option) => {
                          handleSelect(`country`, option, [`state`, `city`]);
                        }}
                        onBlur={() => handleBlur(`country`)}
                        error={errors.country}
                        value={values.country}
                        dropHeight="max-h-[350px]"
                      />
                    </div>
                    <div className="flex-1 w-full font-bold mb-3">
                      How many test kits would you like to order?
                      <CustomInput
                        placeholder="Enter number of kits"
                        type="number"
                        name="quantity"
                        value={values.quantity}
                        onChange={handleChange}
                        onBlur={() => handleBlur(`quantity`)}
                        error={errors.quantity}
                      />
                    </div>
                    <button
                      type="submit"
                      disabled={isLoading}
                      className={`w-full bg-primary py-3 px-6 rounded hover:bg-navy-blue text-base text-[#fff] font-bold item-center flex justify-center ${
                        isLoading && `bg-opacity-50`
                      }`}
                    >
                      {isLoading ? <ButtonSpinner /> : `Join Paid Waitlist`}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default JoinWaitList;
